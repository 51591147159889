import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import Loader from 'Layout/Loader';
import { withLocale } from '@dietlabs/components';
import InputEmail from 'components/FormElements/InputEmailControlled';
import InputPassword from 'components/FormElements/InputPasswordControlled';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';

import setAuthCredentials from '@dietlabs/components/src/Auth/setAuthCredentials';

import { Redirect } from 'react-router';

import getCookie from 'utils/getCookie';

const BuyRegisterForm = ({
    t,
    register,
    redirectToPathAfterSuccess,
    checkAcceptContitions,
    trackFirebaseEvent,
    userDataOnDemand,
}) => {
    const [loginData, setLoginData] = useState({
        email: getCookie('onboarding_email') || '',
        password: '',
    });

    const [errors, setErrors] = useState(new ValidationErrors());
    const [preload, setPreload] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const validationRules = {
        email: [
            new RequiredRule({ translator: t }),
            new EmailFormatRule({ translator: t }),
        ],
        password: [new RequiredRule({ translator: t })],
    };

    const handleSubmit = async event => {
        event.preventDefault();

        trackFirebaseEvent('user_action', {
            action: 'tap-stripe_signup',
            location: 'stripe_signup',
            stripe_gdpr: checkAcceptContitions(),
            used_account: 'mail',
        });

        const frontEndErrors = validateFields(validationRules, loginData, t);

        setErrors(prevState => ({
            ...prevState,
            details: frontEndErrors,
        }));

        if (frontEndErrors.length === 0 && checkAcceptContitions()) {
            setPreload(true);

            const request = {
                email: loginData.email,
                password: loginData.password,
                agreementsTermsAndConditions: true,
                agreementsPersonalDataProcessing: true,
            };

            try {
                const response = await register(request);
                const { __typename, token } = response.data.auth.register;

                setPreload(false);

                if (__typename === 'AuthRegisterSuccess') {
                    setAuthCredentials(token);
                    await userDataOnDemand();
                    setShouldRedirect(true);
                } else if (__typename === 'ValidationException') {
                    setErrors(prevState => ({
                        ...prevState,
                        details: response.data.auth.register.details,
                    }));
                }
            } catch (e) {
                throw new Error(`Failed to register, got error: ${e}`);
            }
        }
    };

    const validateInput = (field, value) => {
        if (validationRules[field]) {
            setErrors(prevState => ({
                ...prevState,
                details: validateField(field, value, validationRules[field], {
                    errors: prevState,
                }),
            }));
        }
    };

    const handleInputChange = event => {
        const { name, value } = event.target;

        setLoginData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        validateInput(name, value);
    };

    if (shouldRedirect && redirectToPathAfterSuccess) {
        return <Redirect to={redirectToPathAfterSuccess} />;
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <InputEmail
                    label={t('buy/email')}
                    name="email"
                    autoComplete="username"
                    value={loginData.email}
                    errors={errors}
                    handleChange={handleInputChange}
                    validationRules={validationRules.email}
                />

                <InputPassword
                    label={t('password')}
                    name="password"
                    autoComplete="current-password"
                    value={loginData.password}
                    errors={errors}
                    handleChange={handleInputChange}
                    validationRules={validationRules.password}
                />

                <Button color="primary" className="w-100">
                    {t('buy/continue')}
                </Button>
            </Form>

            {preload ? <Loader /> : ''}
        </>
    );
};

BuyRegisterForm.propTypes = {
    t: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    redirectToPathAfterSuccess: PropTypes.string.isRequired,
    checkAcceptContitions: PropTypes.func.isRequired,
    trackFirebaseEvent: PropTypes.func.isRequired,
    userDataOnDemand: PropTypes.func.isRequired,
};

export default withFirebase(withLocale(BuyRegisterForm));
