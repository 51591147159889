import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_REGISTER } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import scrollToElement from 'components/Helpers/scrollToElement';
import InputSwitch from 'components/FormElements/InputSwitchControlled';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { validateField } from 'view/Validation/validateField';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import getCountry from 'utils/getCountry';
import { PATH_BUY_LOGIN, PATH_BUY } from '../paths';
import RegisterForm from './BuyRegisterForm';

const BuySignUpContainer = ({
    t,
    register,
    trackFirebaseEvent,
    userDataOnDemand,
}) => {
    const [errors, setErrors] = useState(new ValidationErrors());
    const [acceptConditions, setAcceptConditions] = useState(false);
    const switchEl = useRef(0);

    const ueCountries = [
        'Austria',
        'Belgium',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czechia',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Hungary',
        'Ireland',
        'Italy',
        'Latvia',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Netherlands',
        'Poland',
        'Portugal',
        'Romania',
        'Slovakia',
        'Slovenia',
        'Spain',
        'Sweden',
    ];

    const isFromUe = ueCountries.includes(getCountry());
    const isFromTikTok = window.location.search.includes('c=tiktok');

    useEffect(() => {
        trackFirebaseEvent('screen_view', {
            firebase_screen: 'stripe_signup',
        });
    }, []);

    const validationRules = {
        acceptConditions: [
            new RequiredRule({
                translator: t,
                messages: {
                    empty: t('cart/accept-condition/validation/required'),
                },
            }),
        ],
    };

    const validateInput = (field, value) => {
        if (validationRules[field]) {
            setErrors(prevState => ({
                ...prevState,
                details: validateField(field, value, validationRules[field], {
                    errors: prevState,
                }),
            }));
        }
    };

    const handleAcceptConditions = event => {
        setAcceptConditions(event.target.checked);
        validateInput(event.target.name, event.target.checked);

        trackFirebaseEvent('user_action', {
            action: 'tap-stripe_dgpr_comply',
            location: 'stripe_signup',
            stripe_gdpr: acceptConditions,
        });
    };

    const checkAcceptContitions = () => {
        if (isFromUe) {
            validateInput('acceptConditions', acceptConditions);
            if (acceptConditions) {
                return true;
            }
            scrollToElement(switchEl.current);
            return false;
        }
        return true;
    };

    return (
        <Layout page="buy">
            <Container>
                <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                        <h1 className="text-center my-4 heading-3 semi-bold">
                            {t('buy/sign-up')}
                        </h1>

                        {isFromUe && (
                            <div ref={switchEl}>
                                <InputSwitch
                                    label={t('buy/acceptance')}
                                    id="acceptConditions"
                                    className="left-side"
                                    name="acceptConditions"
                                    value={acceptConditions}
                                    handleChange={handleAcceptConditions}
                                    checked={acceptConditions}
                                    errors={errors}
                                    validationRules={
                                        validationRules.acceptConditions
                                    }
                                />
                            </div>
                        )}

                        <ExternalProviderContainer
                            returnUrl={RETURN_URL_REGISTER}
                            state="stripe"
                            checkAcceptContitions={checkAcceptContitions}
                            roundedBtn
                            fullWidth
                            displayGoogleButton={!isFromTikTok}
                        />

                        <p className="with-line text-center stroke-dark my-4 small">
                            <span>{t('buy/continue-with-e-mail')}</span>
                        </p>
                        <RegisterForm
                            register={register}
                            redirectToPathAfterSuccess={PATH_BUY}
                            checkAcceptContitions={checkAcceptContitions}
                            userDataOnDemand={userDataOnDemand}
                        />
                        <p className="small mt-3 mb-5 text-center">
                            {t('buy/already-have-an-account')}{' '}
                            <Link to={PATH_BUY_LOGIN}>{t('buy/log-in')}</Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

BuySignUpContainer.propTypes = {
    t: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    trackFirebaseEvent: PropTypes.func.isRequired,
    userDataOnDemand: PropTypes.func.isRequired,
};

export default withFirebase(withLocale(BuySignUpContainer));
