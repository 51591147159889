import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { PATHS } from 'config/paths';
import withLoading from 'Layout/withLoading';
import UnauthorizedException from 'view/Error/UnauthorizedException';
import { formatIsoDate } from 'utils/dateFormatter';
import CopyConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/Copy/CopyConnectorApollo';
import SetMealEatenConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/EatenMeal/SetMealEatenConnectorApollo';
import AddMealToFavoritesConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/AddMealToFavorites/AddMealToFavoritesConnectorApollo';
import RemoveMealFromFavoritesConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/RemoveMealFromFavorites/RemoveMealFromFavoritesConnectorApollo';
import InsertFavoriteToDayplanConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/InsertFavoriteToDayplan/InsertFavoriteToDayplanConnectorApollo';
import DayReplacementsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/ReplaceDay/DayReplacementsConnectorApollo';
import ReplaceDayConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/ReplaceDay/ReplaceDayConnectorApollo';
import MealReplacementsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/ReplaceMeal/MealReplacementsConnectorApollo';
import ReplaceMealConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/ReplaceMeal/ReplaceMealConnectorApollo';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import Timeline from '../DayView/components/DayPlanTimeline';
import { PATH_DAY_PLAN_INDEX_WITH_DATE } from '../DayPlanPaths';
import * as types from '../DayPlanTypes';
import CurrentWeightRequired from './CurrentWeightRequired';
import DayViewContainer from '../DayView/DayViewContainer';
import DayPlanIndexPlaceholder from './DayPlanIndexPlaceholder';
import DietInFuture from './DietInFuture';
import GoalLost from './GoalLost';
import GoalReached from './GoalReached';
import PaymentPending from './PaymentPending';

class DayPlanIndexContainer extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        dietAvailabilityDate: PropTypes.instanceOf(Date),
        dailyDietPlan: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            date: PropTypes.instanceOf(Date),
            // for pending payments:
            token: PropTypes.string,
            description: PropTypes.string,
            dietId: PropTypes.number,
            dietEndDate: PropTypes.string,
        }).isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()),
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        prefetchSurroundingDays: PropTypes.func.isRequired,
        prefetchMealDetails: PropTypes.func.isRequired,
        // fastingStatistics: PropTypes.shape().isRequired,
        dietId: PropTypes.number.isRequired,
        recipeNotesForNextDay: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    static defaultProps = {
        dietAvailabilityDate: undefined,
        categories: undefined,
    };

    renderTimeline = () => (
        <Timeline
            availableDays={this.props.availableDays}
            date={this.props.date}
            loading={false}
        />
    );

    render() {
        const type = this.props.dailyDietPlan.__typename;

        if (type === types.CURRENT_WEIGHT_REQUIRED) {
            return (
                <CurrentWeightRequired
                    renderTimeline={() => this.renderTimeline()}
                />
            );
        }

        if (type === types.DAY_PLAN) {
            return (
                <DayReplacementsConnectorApollo
                    date={this.props.dailyDietPlan.date}
                >
                    {({ loadDayPlanDayReplacements }) => (
                        <ReplaceDayConnectorApollo
                            date={this.props.dailyDietPlan.date}
                        >
                            {({ replaceDay }) => (
                                <MealReplacementsConnectorApollo
                                    date={this.props.dailyDietPlan.date}
                                >
                                    {({ loadDayPlanMealReplacements }) => (
                                        <ReplaceMealConnectorApollo
                                            date={this.props.dailyDietPlan.date}
                                        >
                                            {({ replaceMeal }) => (
                                                <CopyConnectorApollo
                                                    date={
                                                        this.props.dailyDietPlan
                                                            .date
                                                    }
                                                >
                                                    {({ copyDayPlan }) => (
                                                        <SetMealEatenConnectorApollo
                                                            date={
                                                                this.props
                                                                    .dailyDietPlan
                                                                    .date
                                                            }
                                                        >
                                                            {({
                                                                setMealEaten,
                                                            }) => (
                                                                <AddMealToFavoritesConnectorApollo
                                                                    date={
                                                                        this
                                                                            .props
                                                                            .dailyDietPlan
                                                                            .date
                                                                    }
                                                                >
                                                                    {({
                                                                        addMealToFavorites,
                                                                    }) => (
                                                                        <RemoveMealFromFavoritesConnectorApollo
                                                                            date={
                                                                                this
                                                                                    .props
                                                                                    .dailyDietPlan
                                                                                    .date
                                                                            }
                                                                        >
                                                                            {({
                                                                                removeMealFromFavorites,
                                                                            }) => (
                                                                                <InsertFavoriteToDayplanConnectorApollo
                                                                                    date={
                                                                                        this
                                                                                            .props
                                                                                            .dailyDietPlan
                                                                                            .date
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        insertFavoriteToDayplan,
                                                                                    }) => (
                                                                                        <DayViewContainer
                                                                                            availableDays={
                                                                                                this
                                                                                                    .props
                                                                                                    .availableDays
                                                                                            }
                                                                                            dailyDietPlan={
                                                                                                this
                                                                                                    .props
                                                                                                    .dailyDietPlan
                                                                                            }
                                                                                            categories={
                                                                                                this
                                                                                                    .props
                                                                                                    .categories
                                                                                            }
                                                                                            renderTimeline={() =>
                                                                                                this.renderTimeline()
                                                                                            }
                                                                                            goal={
                                                                                                this
                                                                                                    .props
                                                                                                    .goal
                                                                                            }
                                                                                            isHolidayMenu={
                                                                                                this
                                                                                                    .props
                                                                                                    .isHolidayMenu
                                                                                            }
                                                                                            isTimeToNagForCurrentMeasurement={
                                                                                                this
                                                                                                    .props
                                                                                                    .isTimeToNagForCurrentMeasurement
                                                                                            }
                                                                                            copyDayPlan={
                                                                                                copyDayPlan
                                                                                            }
                                                                                            alreadyCopied={
                                                                                                this
                                                                                                    .props
                                                                                                    .alreadyCopied
                                                                                            }
                                                                                            loadDayPlanMealReplacements={
                                                                                                loadDayPlanMealReplacements
                                                                                            }
                                                                                            replaceMeal={
                                                                                                replaceMeal
                                                                                            }
                                                                                            loadDayPlanDayReplacements={
                                                                                                loadDayPlanDayReplacements
                                                                                            }
                                                                                            replaceDay={
                                                                                                replaceDay
                                                                                            }
                                                                                            prefetchSurroundingDays={
                                                                                                this
                                                                                                    .props
                                                                                                    .prefetchSurroundingDays
                                                                                            }
                                                                                            prefetchMealDetails={
                                                                                                this
                                                                                                    .props
                                                                                                    .prefetchMealDetails
                                                                                            }
                                                                                            // fastingStatistics={
                                                                                            //     this.props
                                                                                            //         .fastingStatistics
                                                                                            // }
                                                                                            setMealEaten={
                                                                                                setMealEaten
                                                                                            }
                                                                                            addMealToFavorites={
                                                                                                addMealToFavorites
                                                                                            }
                                                                                            removeMealFromFavorites={
                                                                                                removeMealFromFavorites
                                                                                            }
                                                                                            insertFavoriteToDayplan={
                                                                                                insertFavoriteToDayplan
                                                                                            }
                                                                                            dietId={
                                                                                                this
                                                                                                    .props
                                                                                                    .dietId
                                                                                            }
                                                                                            recipeNotesForNextDay={
                                                                                                this
                                                                                                    .props
                                                                                                    .recipeNotesForNextDay
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </InsertFavoriteToDayplanConnectorApollo>
                                                                            )}
                                                                        </RemoveMealFromFavoritesConnectorApollo>
                                                                    )}
                                                                </AddMealToFavoritesConnectorApollo>
                                                            )}
                                                        </SetMealEatenConnectorApollo>
                                                    )}
                                                </CopyConnectorApollo>
                                            )}
                                        </ReplaceMealConnectorApollo>
                                    )}
                                </MealReplacementsConnectorApollo>
                            )}
                        </ReplaceDayConnectorApollo>
                    )}
                </DayReplacementsConnectorApollo>
            );
        }

        if (type === types.DAY_REDIRECT) {
            return (
                <Redirect
                    to={PATH_DAY_PLAN_INDEX_WITH_DATE.replace(
                        ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                        formatIsoDate(this.props.dailyDietPlan.date)
                    )}
                    data-test="day-redirect"
                />
            );
        }

        if (type === types.DIET_IN_FUTURE) {
            return (
                <DietInFuture
                    date={this.props.dietAvailabilityDate}
                    renderTimeline={() => this.renderTimeline()}
                />
            );
        }

        if (type === types.DIET_INTRO) {
            callNativeAppRemoveLastRoute();
            return (
                <Redirect
                    data-test="diet-intro"
                    to={{
                        pathname: PATHS.DIET_INTRO,
                        state: { date: this.props.date },
                    }}
                />
            );
        }

        if (type === types.DIET_SETTINGS) {
            callNativeAppRemoveLastRoute();
            return (
                <Redirect to={PATHS.DIET_SETTINGS} data-test="diet-settings" />
            );
        }

        if (type === types.GOAL_LOST) {
            return <GoalLost renderTimeline={() => this.renderTimeline()} />;
        }

        if (type === types.GOAL_REACHED) {
            return <GoalReached renderTimeline={() => this.renderTimeline()} />;
        }

        if (type === types.PAYMENT_PENDING) {
            return (
                <PaymentPending
                    token={this.props.dailyDietPlan.token}
                    description={this.props.dailyDietPlan.description}
                />
            );
        }

        if (type === types.UNAUTHORIZED) {
            return (
                <UnauthorizedException
                    accessTo={this.props.dailyDietPlan.date}
                />
            );
        }

        throw new Error(`Unknown day plan type: '${type}'`);
    }
}

export { DayPlanIndexContainer };
export default withLoading(DayPlanIndexContainer, DayPlanIndexPlaceholder);
