import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import { withLocale } from '@dietlabs/components';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

import { formatIsoDate } from 'utils/dateFormatter';
import scrollTo from 'components/Helpers/scrollTo';
import scrollToElement from 'components/Helpers/scrollToElement';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

class Timeline extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activeDate: PropTypes.instanceOf(Date).isRequired,
        dates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired)
            .isRequired,
        children: PropTypes.func.isRequired,
    };

    state = {
        clickedDate: null,
    };

    listRef = React.createRef();

    activeItemRef = React.createRef();

    scrollLeftRef = React.createRef();

    scrollRightRef = React.createRef();

    componentDidMount() {
        window.addEventListener('resize', () =>
            this.scrollToActive({ behavior: 'jump' })
        );
        this.scrollToActive({ behavior: 'jump' });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () =>
            this.scrollToActive({ behavior: 'jump' })
        );
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.activeDate.valueOf() !== this.props.activeDate.valueOf()
        ) {
            this.scrollToActive();
        }
    }

    formatDate = date => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        const dayName = t(`meal/weekday${weekday}-short`);
        // return `${dayName} ${day0}.${month0}`;

        return (
            <React.Fragment>
                {dayName}
                <br />
                <span>{`${month0}/${day0}`}</span>
            </React.Fragment>
        );
    };

    scrollToActive(options) {
        const activeItem = this.activeItemRef.current;
        if (!activeItem) {
            return;
        }
        const list = this.listRef.current;
        scrollToElement(activeItem, {
            parent: list,
            align: 'center',
            ...options,
        });
    }

    scrollHorizontally = sgn => {
        const list = this.listRef.current;
        const scrollLeft = this.scrollLeftRef.current;
        const scrollRight = this.scrollRightRef.current;
        const left =
            list.scrollLeft +
            sgn *
                (list.offsetWidth -
                    scrollLeft.offsetWidth -
                    scrollRight.offsetWidth);
        scrollTo(list, { top: 0, left });
    };

    scrollLeft = () => {
        this.scrollHorizontally(-1);
    };

    scrollRight = () => {
        this.scrollHorizontally(1);
    };

    renderScrollLeft() {
        return (
            <button
                className="timeline-arrow left"
                onClick={() => this.scrollLeft()}
                type="button"
                ref={this.scrollLeftRef}
                data-test="left"
            >
                <span className="SVGInline">
                    <LeftArrow />
                </span>
            </button>
        );
    }

    renderScrollRight() {
        return (
            <button
                className="timeline-arrow right"
                onClick={() => this.scrollRight()}
                type="button"
                ref={this.scrollRightRef}
                data-test="right"
            >
                <span className="SVGInline">
                    <RightArrow />
                </span>
            </button>
        );
    }

    renderItem = date => {
        const { activeDate, children: renderDate } = this.props;

        const { clickedDate } = this.state;

        const active = date.valueOf() === activeDate.valueOf();

        const clicked = clickedDate
            ? date.valueOf() === clickedDate.valueOf
            : false;

        const linkClassNames = ['nav-link'];
        if (active) {
            linkClassNames.push('active');
        }
        if (clicked) {
            linkClassNames.push('clicked');
        }

        return (
            <li
                className="nav-item"
                key={date}
                ref={active ? this.activeItemRef : null}
                data-test={active ? 'active' : false}
            >
                {renderDate({
                    date,
                    active,
                    clicked,
                    isoDate: formatIsoDate(date),
                    formattedDate: this.formatDate(date),
                    linkClassName: linkClassNames.join(' '),
                    formatDate: this.formatDate,
                })}
            </li>
        );
    };

    render() {
        const { dates } = this.props;

        return (
            <Fragment>
                <section className="timeline">
                    <Container>
                        <nav>
                            {this.renderScrollLeft()}
                            {this.renderScrollRight()}
                            <ul ref={this.listRef} data-test="list">
                                {dates.map(this.renderItem)}
                            </ul>
                        </nav>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export { Timeline };
export default withLocale(Timeline);
