import React from 'react';
import LoginConnectorApollo from '@dietlabs/components/src/Auth/Login/ByEmailAndPassword/Connector/Apollo/Login/LoginConnectorApollo';
import UserDataOnDemand from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import BuyLogInContainer from './BuyLogInContainer';

const BuySignUpPage = () => {
    return (
        <LoginConnectorApollo>
            {({ login }) => (
                <UserDataOnDemand>
                    {({ ...userDataOnDemand }) => (
                        <BuyLogInContainer
                            {...{ login }}
                            {...userDataOnDemand}
                        />
                    )}
                </UserDataOnDemand>
            )}
        </LoginConnectorApollo>
    );
};

export default BuySignUpPage;
