import React from 'react';
import UserDataOnDemand from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataOnDemandConnectorApollo';
import RegisterConnectorApollo from '../Connector/Apollo/Register/RegisterConnectorApollo';
import BuySignUpContainer from './BuySignUpContainer';

const BuySignUpPage = () => {
    return (
        <RegisterConnectorApollo>
            {({ register }) => (
                <UserDataOnDemand>
                    {({ ...userDataOnDemand }) => (
                        <BuySignUpContainer
                            {...{ register }}
                            {...userDataOnDemand}
                        />
                    )}
                </UserDataOnDemand>
            )}
        </RegisterConnectorApollo>
    );
};

export default BuySignUpPage;
